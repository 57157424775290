import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { fabric } from "fabric";
import Header from "./Header";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Heart from "../assets/heart.svg";
import Download from "../assets/downArrow.svg";
import GreyArrow from "../assets/greyLeftAr.png";
import dropdown from "../assets/arrow_drop_down_24px.svg";
import AllLogoIcn from "../assets/AllLogo.svg";
import EDitIcn from "../assets/PencilIconEditor.svg";
import BluePemcil from "../assets/bluePencil.svg";
import BlackAllLogoIcn from "../assets/blackAllLogoIcon.svg";
import Loader from "./Loader.js";
import startICN from "../assets/star.svg";
import startICNBLACK from "../assets/starBlack.svg";
import checkListTemplate from "../assets/checkListEditor.svg";
import RefreshIcon from "../assets/refresHIcone.svg";
import TemplateOptionIcn from "../assets/templteOptions.svg";
import edit2 from "../assets/edit2.svg";
import edit3 from "../assets/edit3.svg";
import LabelIcon from "../assets/label.svg";
import Select from "react-select";

import { Modal, Button } from "react-bootstrap";
import {
  ViewtemplateText,
  options_select_drop_feedback,
  Analytics_text,
  industryOptions,
  industryOptions2,
} from "./../CommonJquery/WebsiteText";
import {
  server_post_data,
  get_admin_template_view_templates,
  delete_admin_template,
  toggle_template_fav,
  save_update_admin_templates,
  update_template_industry,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  combiled_form_data,
} from "../CommonJquery/CommonJquery";

import {
  storeData,
  retrieveData,
  storeDataSubscription,
} from "../LocalConnection/LocalConnection.js";
function ViewTemplates() {
  const navigate = useNavigate();
  const optionEditorRef = useRef(null);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [showCheckboxList, setShowCheckboxList] = useState(false);
  const [showOptions, setShowshowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive12, setIsActive12] = useState(false);
  const [isActive122, setIsActive122] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setIsSelected] = useState(options_select_drop_feedback[2]);
  const [activeScreen, setActiveScreen] = useState("AllLogo");
  const [isBlackIcon, setIsBlackIcon] = useState(false);
  const [isBlackIcon2, setIsBlackIcon2] = useState(false);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef12 = useRef(null);
  const dropdownRef122 = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownRef3 = useRef(null);
  const [industryData, setIndustryData] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [logos, setLogos] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const [menuIsOpen2, setMenuIsOpen2] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customDateActive, setCustomDateActive] = useState(false);
  const [showEditOptions, setshowEditOptions] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");
  const [selectedTemplateType, setSelectedTemplateType] = useState([]);
  const [templatetype, settemplatetype] = useState();
  const [Favorites, setFavorites] = useState("All");
  const [Favorites2, setFavorites2] = useState("All");
  const [selectedOption2, setSelectedOption2] = useState("All");
  const [deleteTemplatemodal, setDeleteTemplatemodal] = useState(false);
  const [moreOption, setmoreOption] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("this_month"); // Default to "this_month"
  const [selectedIndustry, setSelectedIndustry] = useState("All");
  const [selectedColor, setSelectedColor] = useState("All");
  const images1 = [
    { description: "Logo", route: "/createlogohome" },
    { description: "Letterhead", route: "/home/Letterhead" },
    { description: "Envelop", route: "/home/Envelops" },
    { description: "Business Card", route: "/home/Businesscard" },
    { description: "Invitation", route: "/home/InvitationCard" },
  ];

  const customStyles2 = {
    control: (base) => ({
      ...base,
      fontWeight: 500,
      fontSize: 13,
      color: "black",
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: 13,
      fontWeight: 500,
      color: "black",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#a7a7a7",
      fontSize: 13,
    }),
    option: (base) => ({
      ...base,
      color: "#a7a7a7",
      fontSize: 13,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 !important",
    }),
  };

  useEffect(() => {
    setSelectedTemplateType(images1);

    select_templatetype(retrieveData("templatetype"), "1");
    master_data_get_templates("", 0, retrieveData("templatetype"));
  }, []);

  // This is the function which sets the startDate and endDate

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const select_dropdown = (itemsdata) => {
    setIsSelected(itemsdata);
    setIsActive(!isActive);
    if (itemsdata.datepicker_show) {
      setCustomDateActive(true);
    } else {
      setCustomDateActive(false);
      setSelectedTemplates([]);
      master_data_get_templates(itemsdata.value, 1);
    }
  };

  const select_dropdown1 = (itemsdata) => {
    if (!itemsdata.value) {
      itemsdata.value = "All";
    }
    setSelectedOption(itemsdata.value);
    setIsActive1(!isActive1);
    setSelectedTemplates([]);
    master_data_get_templates(itemsdata.value, 2);
  };

  const select_templatetype = (itemsdata, first_call = 0) => {
    console.log(itemsdata);
    if (itemsdata != 1) {
      if (itemsdata !== "Logo") {
        storeData("templatetype", itemsdata);
        // Hide the elements by setting their display style to 'none'
        document.querySelector(".industrytype").style.display = "none";
        document.querySelector(".colortype").style.display = "none";
        document.querySelector(".Industrypopupbtn").style.display = "none";
      } else {
        // Show the elements by setting their display style to 'block' or any desired display style
        document.querySelector(".industrytype").style.display = "block";
        document.querySelector(".colortype").style.display = "block";
        document.querySelector(".Industrypopupbtn").style.display = "block";
        storeData("templatetype", "Logo");
      }
      master_data_get_templates(itemsdata, 4);
      settemplatetype(itemsdata);
    } else {
      storeData("templatetype", "Logo");
      settemplatetype("Logo");
    }

    if (first_call == 0) {
      setIsActive12(!isActive12);
      ///setSelectedTemplates([]);
    }
    // master_data_get_templates(itemsdata.value, 2);
  };

  const select_Favorites = (itemsdata, first_call = 0) => {
    console.log(itemsdata);

    //storeData("Favorites",itemsdata);
    setFavorites(itemsdata);

    if (itemsdata == "Favorites") {
      setFavorites2("1");
    } else if (itemsdata == "Not Favorites") {
      setFavorites2("0");
    } else {
      setFavorites2(itemsdata);
    }

    setIsActive122(!isActive122);
    ///setSelectedTemplates([]);
    master_data_get_templates(itemsdata, 5);
  };

  const select_dropdown2 = (itemsdata) => {
    if (!itemsdata.value) {
      itemsdata.value = "All";
    }
    setSelectedOption2(itemsdata.value);
    setIsActive2(!isActive2);
    setSelectedTemplates([]);
    master_data_get_templates(itemsdata.value, 3);
  };

  const TemplateImgs = [];

  const [toggled, setToggled] = useState(
    Array(TemplateImgs.length).fill(false)
  );

  // Function to handle button click
  const handleToggle = async (event, index, primary_id) => {
    event.preventDefault();
    try {
      // Update the local state without causing a page reload
      const updatedLogos = logos.map((logo, i) => {
        if (i === index) {
          const updatedFavStatus = logo.template_fav === "1" ? "0" : "1";
          return { ...logo, template_fav: updatedFavStatus };
        }
        return logo;
      });

      setLogos(updatedLogos);

      // Call the API to update the favorite status in the backend
      await master_data_action_update_fav(primary_id);
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    }
  };

  const handleSaveSelected = async (template) => {
    try {
      console.log(`Saving template with ID: ${template.primary_id}`);
      const response = await axios.post("/api/saveTemplate", {
        templateId: template.primary_id,
        // Include other data as necessary
      });
      console.log(
        `Template ${template.primary_id} saved successfully. Response:`,
        response.data
      );
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  const handleRemoveFromSaved = async (template) => {
    try {
      console.log(`Removing template with ID: ${template.primary_id}`);
      const response = await axios.post("/api/removeTemplate", {
        templateId: template.primary_id,
        // Include other data as necessary
      });
      console.log(
        `Template ${template.primary_id} removed successfully. Response:`,
        response.data
      );
    } catch (error) {
      console.error("Error removing template:", error);
    }
  };
  // Function to handle button click
  const AlliconeColorChange = (index) => {
    setIsBlackIcon(true);
    setIsBlackIcon2(false);
    setActiveScreen("AllLogo");
  };
  const EditiconeColorChange = (index) => {
    setIsBlackIcon2(true);
    setIsBlackIcon(false);
    setActiveScreen("Editor");
    navigate("/admin_canvas_new");
  };

  const master_data_action_update = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    fd.append("flag", "3");
    await server_post_data(delete_admin_template, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get_templates("", 0);
          toast.success("Item deleted successfully!");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  // const master_data_action_update_fav = async (call_id) => {
  //   // setshowLoaderAdmin(true);
  //   const fd = new FormData();
  //   fd.append("primary_id", call_id);
  //   fd.append("flag", "3");
  //   await server_post_data(toggle_template_fav, fd)
  //     .then((Response) => {
  //       // setshowLoaderAdmin(false);
  //       if (Response.data.error) {
  //         handleError(Response.data.message);
  //       } else {
  //         master_data_get_templates("", 0);
  //         toast.success("Added to Favorites!");
  //       }
  //     })
  //     .catch((error) => {
  //       // setshowLoaderAdmin(false);
  //     });
  // };

  const master_data_action_update_fav = async (call_id) => {
    try {
      const fd = new FormData();
      fd.append("primary_id", call_id);
      fd.append("flag", "3");

      // Call the API to update the favorite status in the backend
      const response = await server_post_data(toggle_template_fav, fd);

      if (response.data.error) {
        handleError(response.data.message);
      } else {
        // toast.success("Favorite status updated!");
        // No need to re-fetch data or reload the page
      }
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    } finally {
      // Ensure the loader is hidden regardless of success or failure
      setshowLoaderAdmin(false);
    }
  };

  const handleLabelmodal = (index) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // off the Edit option when i click out of this div

  const handleoptionclickOutside = useCallback((event) => {
    if (
      optionEditorRef.current &&
      !optionEditorRef.current.contains(event.target)
    ) {
      setshowEditOptions(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleoptionclickOutside);
    return () => {
      document.removeEventListener("mousedown", handleoptionclickOutside);
    };
  }, [handleoptionclickOutside]);

  const handleShowEditingOptions = (index) => {
    setshowEditOptions((prev) => (prev === index ? null : index));
  };

  //shortcutKey
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      const newOption = { value: inputValue, label: inputValue };
      setSelectedOptions([...selectedOptions, newOption]);
      setInputValue("");
      setMenuIsOpen(false);
    }
  };

  const handleInputChange = (newValue, { action }) => {
    setInputValue(newValue);
    if (action === "input-change") {
      setMenuIsOpen(true); // Open the menu when there is input
    }
  };

  const handleLabel = (selected) => {
    setSelectedOptions(selected);
    setMenuIsOpen(false);
  };
  //templstes

  //   Function to Get Filtered Data
  const master_data_get_templates = async (value_get, flag, vluenext = 0) => {
    let template_name = "";
    let template_industry = selectedIndustry;
    let template_colors = selectedColor;
    let select_type = selectedDateRange;
    let templatetype2;
    let sort_type = Favorites2;

    // Update the correct filter based on the flag
    if (flag === 1) {
      setSelectedDateRange(value_get);
      select_type = value_get;
    } else if (flag === 2) {
      setSelectedIndustry(value_get);
      template_industry = value_get;
    } else if (flag === 3) {
      setSelectedColor(value_get);
      template_colors = value_get;
    } else if (flag === 5) {
      if (value_get == "Favorites") {
        sort_type = "1";
      } else if (value_get == "Not Favorites") {
        sort_type = "0";
      } else {
        sort_type = value_get;
      }
    }

    if (flag === 4) {
      templatetype2 = value_get;
    } else {
      if (vluenext != 0) {
        templatetype2 = vluenext;
      } else {
        templatetype2 = templatetype;
      }
    }

    setshowLoaderAdmin(true);
    const fd = new FormData();
    const current_date = new Date();
    let start_date = new Date();
    let end_date = new Date();

    if (select_type === "today") {
      start_date = start_date_fn(current_date);
      end_date = end_date_fn(current_date);
    } else if (select_type === "last_7_days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      start_date = start_date_fn(sevenDaysAgo);
      end_date = end_date_fn(current_date);
    } else if (select_type === "this_month") {
      const firstDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        0
      );
      start_date = start_date_fn(firstDayOfMonth);
      end_date = end_date_fn(lastDayOfMonth);
    } else if (select_type === "last_month") {
      const firstDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() - 1,
        1
      );
      const lastDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        0
      );
      start_date = start_date_fn(firstDayOfLastMonth);
      end_date = end_date_fn(lastDayOfLastMonth);
    } else if (select_type === "this_year") {
      const firstDayOfYear = new Date(current_date.getFullYear(), 0, 1);
      start_date = start_date_fn(firstDayOfYear);
      end_date = end_date_fn(current_date);
    }

    function start_date_fn(start_date) {
      const start_year = start_date.getFullYear();
      const start_month = (start_date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const start_day = start_date.getDate().toString().padStart(2, "0");
      return `${start_year}-${start_month}-${start_day}`;
    }

    function end_date_fn(end_date) {
      const end_year = end_date.getFullYear();
      const end_month = (end_date.getMonth() + 1).toString().padStart(2, "0");
      const end_day = end_date.getDate().toString().padStart(2, "0");
      return `${end_year}-${end_month}-${end_day}`;
    }

    // Append all the current filter values to the FormData
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("template_industry", template_industry);
    fd.append("template_colors", template_colors);
    fd.append("template_name", template_name);
    fd.append("templatetype", templatetype2);
    fd.append("sort_type", sort_type);

    await server_post_data(get_admin_template_view_templates, fd)
      .then((Response) => {
        const responseData = Response.data.message.data_temp;
        const responseData_in = Response.data.message.data_industry;

        const parsedData = responseData.map((item) => ({
          templateName: item.template_name,
          templateCanvas: item.template_canvas,
          ...item,
        }));

        const uniqueSortedIndustries = [
          ...new Set(responseData_in.map((item) => item.industry_name)),
        ].sort();

        const industryOptions = [
          { label: "All", value: "" },
          ...uniqueSortedIndustries.map((industry) => ({
            label: industry,
            value: industry,
          })),
        ];

        const colorOptions = [
          { label: "All", value: "" },
          { label: "Red", value: "Red" },
          { label: "Blue", value: "Blue" },
          { label: "Green", value: "Green" },
          { label: "Orange", value: "Orange" },
          { label: "Yellow", value: "Yellow" },
          { label: "Teal", value: "Teal" },
          { label: "Purple", value: "Purple" },
          { label: "Pink", value: "Pink" },
          { label: "Black", value: "Black" },
        ];

        setIndustryData(industryOptions);
        setColorOptions(colorOptions);
        setLogos(parsedData);
        setshowLoaderAdmin(false);

        // Response.data.message.custom_font.forEach((font) => {
        //   const fontFace = new FontFace(font.family, `url(${font.src_file})`);

        //   fontFace
        //     .load()
        //     .then((loadedFace) => {
        //       document.fonts.add(loadedFace);
        //     })
        //     .catch((error) => {
        //       console.error(`Error loading font "${font.family}":`, error);
        //     });
        // });

        // const fontStyles = Response.data.message.fontlist
        //   .filter((font) => font.files && font.files.regular)
        //   .map((font) => {
        //     return `@font-face {
        //                 font-family: '${font.family}';
        //                 font-style: normal;
        //                 font-weight: 400;
        //                 src: url(${font.files.regular});
        //             }`;
        //   })
        //   .join("\n");

        // const styleElement = document.createElement("style");
        // styleElement.textContent = fontStyles;
        // document.head.appendChild(styleElement);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  const createCanvas = (ref, logoData) => {
    if (!ref) return;

    const templatejson = JSON.parse(logoData.templateCanvas);
    ///console.log(templatejson);
    if (!templatejson.scaled) {

      const originalWidth = 793.70;
      const originalHeight = 1124.41;
      const newCanvasWidth = 250;
      const newCanvasHeight = 300;

    const scaleFactorWidth = newCanvasWidth / originalWidth;
    const scaleFactorHeight = newCanvasHeight / originalHeight;
    let scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      const canvas = new fabric.Canvas(ref, {
        width: 250,
        height: 300,
      });

      console.log("Background Color/Gradient:", templatejson.background);

      
      canvas.backgroundColor = templatejson.background;
      console.log("s1");
      console.log(templatejson);
      if (templatejson.objects) {
        console.log("s2");

        templatejson.scaled = true;

        templatejson.objects.forEach((objData) => {
          objData.scaleX = (objData.scaleX || 1) * scaleFactor;
          objData.scaleY = (objData.scaleY || 1) * scaleFactor;
          objData.left = (objData.left || 0) * scaleFactor;
          objData.top = (objData.top || 0) * scaleFactor;
          objData.scaled = true;

          // Set selectable to false for all objects
          objData.selectable = false;

          fabric.util.enlivenObjects([objData], (objects) => {
            const origRenderOnAddRemove = canvas.renderOnAddRemove;
            canvas.renderOnAddRemove = false;

            objects.forEach((obj) => {
              canvas.add(obj);
            });

            canvas.renderOnAddRemove = origRenderOnAddRemove;
            canvas.requestRenderAll();
          });
        });

        // Render canvas after all objects are added
        canvas.renderAll();
        return canvas;
      }
    }
  };

  const parseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  //mutli Selector Function

  const handleKeyDown2 = (event) => {
    if (event.key === "Enter" && inputValue2) {
      event.preventDefault();
      const newOption2 = { value: inputValue2, label: inputValue2 };
      setSelectedOptions2([...selectedOptions2, newOption2]);
      setInputValue2("");
      setMenuIsOpen2(false);
    }
  };

  const handleInputChange2 = (newValue, { action }) => {
    setInputValue2(newValue);
    if (action === "input-change") {
      setMenuIsOpen2(true); // Open the menu when there is input
    }
  };

  const handleLabel2 = (selected2) => {
    setSelectedOptions2(selected2);
    setMenuIsOpen2(false);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const validateFields = () => {
    if (selectedOptions.length === 0) {
      setErrorMessage("Please select at least one option.");
      return false;
    }
    setErrorMessage("");
    return true;
  };
  const handleSubmit = () => {
    if (validateFields()) {
      setShowModal(false); // Close the modal if validation passes
    }
  };

  // Function to handle selecting/deselecting templates
  const toggleTemplateSelection = (index, primary_id) => {
    setSelectedTemplates((prevSelected) => {
      console.log(index);
      console.log(primary_id);
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };
  const selectAllTemplates = () => {
    // Check if all templates are already selected
    if (selectedTemplates.length === logos.length) {
      // Deselect all templates
      setSelectedTemplates([]);
    } else {
      // Select all templates
      const allIndexes = logos.map((_, index) => index);
      setSelectedTemplates(allIndexes);
    }

    // Log the selected templates for debugging
    const selectedPrimaryIds = logos.map((template) => template.primary_id);
    console.log("Selected Template Primary IDs:", selectedPrimaryIds);
  };

  // Function to handle deleting selected templates
  const handleDeleteSelected = () => {
    setDeleteTemplatemodal(true); // Show the modal
  };

  const handleCancelDelete = () => {
    setDeleteTemplatemodal(false);
  };

  const DeleteMultiTemplate = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    fd.append("flag", "3");
    await server_post_data(delete_admin_template, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get_templates("", 0);
          toast.success("Item deleted successfully!");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const handleConfirmDelete = () => {
    const templatesToDelete = selectedTemplates.map((index) => logos[index]);
    console.log("Deleting the following templates:", templatesToDelete);
    templatesToDelete.forEach((template) => {
      DeleteMultiTemplate(template.primary_id);
    });

    setSelectedTemplates([]);
    setDeleteTemplatemodal(false);
  };

  //show more option
  //show options
  const moreOptionRef = useRef(null);

  const OptionsMoreClick = () => {
    setmoreOption((prev) => !prev);
  };

  console.log(selectedTemplates);

  const handleStarAllUnstarred = async (event) => {
    try {
      // Map the selected template indices to their corresponding primary IDs
      const selectedPrimaryIds = selectedTemplates.map(
        (index) => logos[index].primary_id
      );

      const updatedLogos = logos.map((logo) => {
        if (
          logo.template_fav !== "1" &&
          selectedPrimaryIds.includes(logo.primary_id)
        ) {
          // Call the API to update the favorite status in the backend
          master_data_action_update_fav(logo.primary_id);
          return { ...logo, template_fav: "1" }; // Update the local state
        }
        return logo;
      });

      setLogos(updatedLogos); // Update the state to reflect the changes
    } catch (error) {
      console.error("Error starring all unstarred logos:", error);
    }
  };

  const handleUnstarAllStarred = async (event) => {
    try {
      // Map the selected template indices to their corresponding primary IDs
      const selectedPrimaryIds = selectedTemplates.map(
        (index) => logos[index].primary_id
      );

      const updatedLogos = logos.map((logo) => {
        if (
          logo.template_fav !== "0" &&
          selectedPrimaryIds.includes(logo.primary_id)
        ) {
          // Call the API to update the favorite status in the backend
          master_data_action_update_fav(logo.primary_id);
          return { ...logo, template_fav: "0" }; // Update the local state
        }
        return logo;
      });

      setLogos(updatedLogos); // Update the state to reflect the changes
    } catch (error) {
      console.error("Error unstarring all starred logos:", error);
    }
  };

  const [industryModal, setIndustryModal] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]); // State for selected industries

  const handleIndustrySelect = (selected) => {
    setSelectedIndustries(selected);
  };

  const HandleIndustrYmoDAL = (e) => {
    e.preventDefault();
    console.log("clicked");
    setIndustryModal(true);
  };

  const handleIndustryCloseModal = () => {
    setIndustryModal(false);
  };

  // Handle industry selection in the modal
  const handleSaveChangesdynamic = async () => {
    console.log("Starting handleSaveChangesdynamic");

    let fd_from = new FormData();

    // Extract only the 'value' properties from selectedIndustries
    const selectedIndustryValues = selectedIndustries
      .map((option) => option.value)
      .join(",");

    if (selectedIndustryValues) {
      setshowLoaderAdmin(true);
      console.log("Loader set to true, starting to append data.");

      // Iterate through selected templates and add industry data
      selectedTemplates.forEach((templateIndex) => {
        const template = logos[templateIndex];
        console.log("Appending data for template:", template.primary_id);

        fd_from.append("main_id", template.primary_id); // Append primary_id
        fd_from.append("template_industry", selectedIndustryValues); // Append industry data
      });

      console.log("Final FormData before sending:", fd_from);

      await server_post_data(update_template_industry, fd_from)
        .then((Response) => {
          console.log("Response received:", Response.data);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            console.error("Error in response:", Response.data.message);
            handleError(Response.data.message);
          } else {
            console.log("Success, updating state.");
            toast.success("Industries updated successfully!");
            setTimeout(() => {
              window.location.reload();
            }, 100);

            // Optionally update the local state to reflect the changes
            const updatedLogos = logos.map((logo, index) => {
              if (selectedTemplates.includes(index)) {
                return {
                  ...logo,
                  industries: selectedIndustries.map((option) => option.label), // Update the industries based on the selected options
                };
              }
              return logo;
            });

            console.log("Updated logos:", updatedLogos);

            setLogos(updatedLogos);
            setSelectedTemplates([]); // Clear selection after update
            handleIndustryCloseModal(); // Close the modal
          }
        })
        .catch((error) => {
          console.error("Error during server post:", error);
          handleError("network");
          setshowLoaderAdmin(false);
        });
    } else {
      console.warn("No industry selected, showing error.");
      handleError("Please Add Industry keyword");
    }
  };

  console.log(`Total number of logos: ${logos.length}`);

  const handleDropdownToggle = () => {
    setIsActive(!isActive);
    setIsActive1(false);
    setIsActive2(false);
    setIsActive12(false);
    setIsActive122(false);
  };

  const handleDropdownToggle2 = () => {
    setIsActive1(!isActive1);
    setIsActive(false);
    setIsActive2(false);
    setIsActive12(false);
    setIsActive122(false);
  };

  const handleDropdownToggle12 = () => {
    setIsActive12(!isActive12);
    setIsActive(false);
    setIsActive2(false);
    setIsActive122(false);
    setIsActive1(false);
  };

  const handleDropdownToggle122 = () => {
    setIsActive122(!isActive122);
    setIsActive(false);
    setIsActive2(false);
    setIsActive12(false);
    setIsActive1(false);
  };

  const handleDropdownToggle3 = () => {
    setIsActive2(!isActive2);
    setIsActive(false);
    setIsActive1(false);
    setIsActive12(false);
    setIsActive122(false);
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      dropdownRef2.current &&
      !dropdownRef2.current.contains(event.target) &&
      dropdownRef3.current &&
      !dropdownRef3.current.contains(event.target) &&
      dropdownRef12.current &&
      !dropdownRef12.current.contains(event.target) &&
      dropdownRef122.current &&
      !dropdownRef122.current.contains(event.target)
    ) {
      setIsActive(false);
      setIsActive1(false);
      setIsActive2(false);
      setIsActive12(false);
      setIsActive122(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head Zindexing">
              <div className="pageNameDiv">
                <p>{ViewtemplateText.TemplateTxt}</p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
              <div className="pageNameDiv   EditOrBttn" style={{ gap: "0" }}>
                <button onClick={EditiconeColorChange}>
                  <img src={isBlackIcon2 ? BluePemcil : EDitIcn} alt="star" />
                  Editor
                </button>
              </div>
            </div>
            <div className="container-fluid">
              {" "}
              <div className="page_body">
                <div className="viewStaff">
                  {activeScreen === "AllLogo" && (
                    <>
                      <div className="headCOntainer">
                        <div className="LeftSideSelecter">
                          <div className=" col-xl-2 col-lg-2">
                            <div
                              className="dropdownCustom marginBttm"
                              ref={dropdownRef}
                            >
                              <div
                                onClick={handleDropdownToggle}
                                className="dropdownCustom-btn"
                              >
                                {Analytics_text.Period_text}: {selected.label}
                                <span
                                  className={
                                    isActive
                                      ? "fas fa-caret-up"
                                      : "fas fa-caret-down"
                                  }
                                >
                                  <img
                                    src={dropdown}
                                    alt="Barley's Dashboard"
                                  />
                                </span>
                              </div>
                              <div
                                className="dropdownCustom-content "
                                style={{ display: isActive ? "block" : "none" }}
                              >
                                {options_select_drop_feedback.map(function (
                                  items,
                                  index
                                ) {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        select_dropdown(items);
                                      }}
                                      className="itemDrop"
                                      key={index}
                                    >
                                      {items.label}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-2 col-lg-2">
                            <div
                              className="dropdownCustom marginBttm"
                              ref={dropdownRef12}
                            >
                              <div
                                onClick={handleDropdownToggle12}
                                className="dropdownCustom-btn"
                              >
                                Template: {templatetype}
                                <span
                                  className={
                                    isActive12
                                      ? "fas fa-caret-up"
                                      : "fas fa-caret-down"
                                  }
                                >
                                  <img
                                    src={dropdown}
                                    alt="Barley's Dashboard"
                                  />
                                </span>
                              </div>
                              <div
                                className="dropdownCustom-content   DropDownHIght"
                                style={{
                                  display: isActive12 ? "block" : "none",
                                }}
                              >
                                {selectedTemplateType.map(function (
                                  items,
                                  index
                                ) {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        select_templatetype(items.description);
                                      }}
                                      className="itemDrop"
                                      key={index}
                                    >
                                      {items.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-2 col-lg-2 industrytype">
                            <div
                              className="dropdownCustom marginBttm"
                              ref={dropdownRef2}
                            >
                              <div
                                onClick={handleDropdownToggle2}
                                className="dropdownCustom-btn"
                              >
                                Industry: {selectedOption}
                                <span
                                  className={
                                    isActive1
                                      ? "fas fa-caret-up"
                                      : "fas fa-caret-down"
                                  }
                                >
                                  <img
                                    src={dropdown}
                                    alt="Barley's Dashboard"
                                  />
                                </span>
                              </div>
                              <div
                                className="dropdownCustom-content   DropDownHIght"
                                style={{
                                  display: isActive1 ? "block" : "none",
                                }}
                              >
                                {industryData.map(function (items, index) {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        select_dropdown1(items);
                                      }}
                                      className="itemDrop"
                                      key={index}
                                    >
                                      {items.label}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-2 col-lg-2 colortype">
                            <div
                              className="dropdownCustom marginBttm"
                              ref={dropdownRef3}
                            >
                              <div
                                onClick={handleDropdownToggle3}
                                className="dropdownCustom-btn"
                              >
                                Color: {selectedOption2}
                                <span
                                  className={
                                    isActive2
                                      ? "fas fa-caret-up"
                                      : "fas fa-caret-down"
                                  }
                                >
                                  <img
                                    src={dropdown}
                                    alt="Barley's Dashboard"
                                  />
                                </span>
                              </div>
                              <div
                                className="dropdownCustom-content DropDownHIght"
                                style={{
                                  display: isActive2 ? "block" : "none",
                                }}
                              >
                                {colorOptions.map(function (items, index) {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        select_dropdown2(items);
                                      }}
                                      className="itemDrop"
                                      key={index}
                                    >
                                      {items.label}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-2 col-lg-2">
                            <div
                              className="dropdownCustom marginBttm"
                              ref={dropdownRef122}
                            >
                              <div
                                onClick={handleDropdownToggle122}
                                className="dropdownCustom-btn"
                              >
                                Type: {Favorites}
                                <span
                                  className={
                                    isActive122
                                      ? "fas fa-caret-up"
                                      : "fas fa-caret-down"
                                  }
                                >
                                  <img
                                    src={dropdown}
                                    alt="Barley's Dashboard"
                                  />
                                </span>
                              </div>
                              <div
                                className="dropdownCustom-content   DropDownHIght"
                                style={{
                                  display: isActive122 ? "block" : "none",
                                }}
                              >
                                <div
                                  onClick={(e) => {
                                    select_Favorites("All");
                                  }}
                                  className="itemDrop"
                                  key="1"
                                >
                                  All
                                </div>
                                <div
                                  onClick={(e) => {
                                    select_Favorites("Favorites");
                                  }}
                                  className="itemDrop"
                                  key="2"
                                >
                                  Favorites
                                </div>

                                <div
                                  onClick={(e) => {
                                    select_Favorites("Not Favorites");
                                  }}
                                  className="itemDrop"
                                  key="3"
                                >
                                  Not Favorites
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RefreshButton">
                          <button
                            className="btn btn-primary Create_Right_Btn btnSave w-100"
                            type="button"
                            placeholder="Search"
                            onClick={() => window.location.reload()}
                          >
                            Refresh
                          </button>
                        </div>
                      </div>
                      {customDateActive && (
                        <div className="row mt-3">
                          <div className="col-xl-3 col-lg-3 bottomAlgin marginBttm">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="startDate"
                                type="date"
                                placeholder="From Date"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="endDate"
                                type="date"
                                placeholder="To Date"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="TemTop mt-3">
                          <div className="AllLogoCustmLogobtn   mobileWIth">
                            {/* <button
                              className="allLogoBtn"
                              onClick={AlliconeColorChange}
                            >
                              <img
                                src={isBlackIcon ? AllLogoIcn : BlackAllLogoIcn}
                                alt="star"
                              />
                              All Templates
                            </button> */}
                            <span className="logo-count ml-2">
                              Logos found: {logos.length}
                            </span>
                          </div>
                          <div
                            className="AllLogoCustmLogobtn2"
                            style={{ position: "relative" }}
                          >
                            {/* <button onClick={handleCheckList}>
                              <img src={checkListTemplate}></img>
                            </button> */}
                            {/* <div className="industryBtttn">
                              <button
                                disabled={selectedTemplates.length === 0}
                                style={{
                                  cursor:
                                    selectedTemplates.length === 0
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={(e) => HandleIndustrYmoDAL(e)}
                              >
                                Industry
                              </button>
                            </div> */}
                            <div
                              className="myLogo Industrypopupbtn"
                              style={{ marginBottom: "3px" }}
                            >
                              {" "}
                              <button
                                type="button"
                                disabled={selectedTemplates.length === 0}
                                style={{
                                  cursor:
                                    selectedTemplates.length === 0
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={(e) => HandleIndustrYmoDAL(e)}
                              >
                                Industry
                              </button>
                            </div>
                            <div
                              className="myLogo"
                              style={{
                                marginBottom: "3px",
                                position: "relative",
                              }}
                            >
                              {/* Main button that opens the dropdown */}
                              <button
                                type="button"
                                disabled={selectedTemplates.length === 0}
                                style={{
                                  cursor:
                                    selectedTemplates.length === 0
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={toggleDropdown}
                              >
                                Favorite / Unfavorite
                              </button>

                              {/* Dropdown content */}
                              {dropdownOpen && (
                                <div
                                  className="dropdown-content"
                                  style={{
                                    position: "absolute",
                                    top: "100%",
                                    left: 0,
                                    backgroundColor: "#f9f9f9",
                                    boxShadow:
                                      "0px 8px 16px rgba(0, 0, 0, 0.1)",
                                    zIndex: 999,
                                    padding: "10px",
                                  }}
                                >
                                  {/* Favorite button */}
                                  <button
                                    type="button"
                                    disabled={selectedTemplates.length === 0}
                                    style={{
                                      cursor:
                                        selectedTemplates.length === 0
                                          ? "not-allowed"
                                          : "pointer",
                                      marginBottom: "3px",
                                    }}
                                    onClick={(event) => {
                                      handleStarAllUnstarred();
                                      setDropdownOpen(false); // Close the dropdown after action
                                    }}
                                  >
                                    <img src={startICNBLACK} alt="star" />{" "}
                                    Favorite
                                  </button>

                                  {/* Unfavorite button */}
                                  <button 
                                    type="button"
                                    disabled={selectedTemplates.length === 0}
                                    style={{
                                      cursor:
                                        selectedTemplates.length === 0
                                          ? "not-allowed"
                                          : "pointer",
                                      marginBottom: "3px",
                                    }}
                                    onClick={(event) => {
                                      handleUnstarAllStarred();
                                      setDropdownOpen(false); // Close the dropdown after action
                                    }}
                                  >
                                    <img src={startICN} alt="star" /> Unfavorite
                                  </button>
                                </div>
                              )}
                            </div>
                            <div
                              className="myLogo"
                              style={{ marginBottom: "3px" }}
                            >
                              {" "}
                              <button
                                className="deltbttn"
                                onClick={selectAllTemplates}
                              >
                                Select All
                              </button>
                            </div>
                            <div
                              className="myLogo"
                              style={{ marginBottom: "3px" }}
                            >
                              {" "}
                              <button
                                onClick={handleDeleteSelected}
                                disabled={selectedTemplates.length === 0}
                                style={{
                                  cursor:
                                    selectedTemplates.length === 0
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                {/* <img src={edit3} alt="edit3" /> */}
                                Delete
                              </button>
                            </div>

                            {/* <div className="LeblImg">
                              {" "}
                              <button
                                className="lebelIcnImg"
                                onClick={handleLabelmodal}
                              >
                                {" "}
                                <img src={LabelIcon}></img>
                              </button>
                            </div> */}
                            {/* <div
                              className="optionsDelete"
                              onClick={OptionsMoreClick}
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={TemplateOptionIcn}
                                alt="TemplateOptionIcn"
                              ></img>
                              {moreOption && (
                                <div className="underCont" ref={moreOptionRef}>
                                  <button
                                    className="deltbttn"
                                    onClick={handleDeleteSelected}
                                    disabled={selectedTemplates.length === 0}
                                  >
                                    <img src={edit3} alt="edit3"></img>Delete
                                  </button>
                                  <button
                                    className="deltbttn"
                                    onClick={selectAllTemplates}
                                  >
                                    <img src={edit3} alt="edit3" /> Select All
                                  </button>
                                </div>
                              )}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="TemplateContainer">
                      <div
                        className="templat"
                        style={{
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <div className="row w-100">
                          {showLoaderAdmin ? (
                            <div>Loading...</div>
                          ) : logos.length > 0 ? (
                            logos.map((logoData, index) => (
                              <div className="widthAccor mb-3">
                                <div key={index} className="image-container">
                                  <div
                                    className="grid-item gridLogo_item"
                                    style={{
                                      height:
                                        templatetype === "Logo"
                                          ? "200px"
                                          : "auto",
                                    }}
                                  >
                                    <div className="ShowLogoCanvas">
                                      <img
                                        src={`https://api.logoacademy.co/assets/${logoData.template_url}`}
                                        alt={logoData.template_name}
                                        className="gallery-image template_radius "
                                      />
                                      {/* <canvas
                                            ref={(ref) =>
                                              createCanvas(ref, logoData)
                                            }
                                          /> */}
                                    </div> 
                                  </div>
                                  <div className="template-name text-center">
                                    {logoData.templateName.length > 13
                                      ? `${logoData.templateName.slice(
                                          0,
                                          20
                                        )}...`
                                      : logoData.templateName}
                                  </div>
                                  <div className="d-flex imgSelect">
                                    <div className="EditTemptIcn">
                                      <button
                                        onClick={() =>
                                          handleShowEditingOptions(index)
                                        }
                                      >
                                        <img
                                          src={TemplateOptionIcn}
                                          alt="Pencil"
                                        />
                                      </button>
                                      {showEditOptions === index && (
                                        <div
                                          className="optionEditor"
                                          ref={optionEditorRef}
                                        >
                                          <Link
                                            to={`/admin_canvas/${logoData.primary_id}`}
                                          >
                                            <button>
                                              <img src={EDitIcn} alt="Pencil" />
                                              <p>Edit</p>
                                            </button>
                                          </Link>
                                          <Link
                                            to={`/admin_canvas_dupticate/${logoData.primary_id}`}
                                          >
                                            <button
                                              style={{ marginRight: "8px" }}
                                            >
                                              <img src={edit2} alt="Pencil" />
                                              <p>Duplicate</p>
                                            </button>
                                          </Link>
                                          <div>
                                            <button
                                              style={{ marginRight: "8px" }}
                                            >
                                              <img src={edit3} alt="Pencil" />
                                              <p
                                                onClick={() => {
                                                  if (
                                                    window.confirm(
                                                      "Are you sure you want to delete this item?"
                                                    )
                                                  ) {
                                                    master_data_action_update(
                                                      logoData.primary_id
                                                    );
                                                  }
                                                }}
                                              >
                                                Delete
                                              </p>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      <div className="logoFoot">
                                        <button className="editLogoBtn">
                                          Edit Logo
                                        </button>
                                        <div>
                                          <button className="saveLogoBtn">
                                            <img src={Heart} alt="Save Logo" />
                                          </button>
                                          <button className="donwnLogoBtn">
                                            <img
                                              src={Download}
                                              alt="Download Logo"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <input
                                      type="checkbox"
                                      checked={selectedTemplates.includes(
                                        index
                                      )}
                                      onChange={() => {
                                        toggleTemplateSelection(
                                          index,
                                          logoData.primary_id
                                        );
                                      }}
                                    />
                                    <button
                                      type="button" // Explicitly set the type to prevent form submission
                                      onClick={(event) => {
                                        event.preventDefault();
                                        handleToggle(
                                          event,
                                          index,
                                          logoData.primary_id
                                        ); // Toggle the favorite status
                                      }}
                                    >
                                      <img
                                        src={
                                          logoData.template_fav === "1"
                                            ? startICNBLACK
                                            : startICN
                                        }
                                        alt="star"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>No logos available</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>New Label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="createRightsForm" id="createRightsForm">
            <div className="row m-0">
              <div className="personalForm">
                <div className="row m-0">
                  <div className={`col-md-8`}>
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        label name:<span>*</span>
                      </label>
                      <div>
                        <Select
                          styles={customStyles2}
                          options={industryData}
                          onChange={handleIndustrySelect} // Handle selection
                          value={selectedIndustries} // Ensure value is bound to selectedIndustries
                          isMulti
                          className={`form-control industry_input inpageinput`}
                          placeholder="Industry"
                          isSearchable
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createRightBtnDiv"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="createBtn"
            variant="secondary"
            onClick={handleIndustryCloseModal}
          >
            Close
          </Button>
          <Button
            className="createBtn"
            variant="primary"
            onClick={handleSaveChangesdynamic} // Update with correct arguments
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      {/**----Delete the template---- */}
      <Modal show={deleteTemplatemodal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Templates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the selected templates?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={industryModal} onHide={handleIndustryCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Industry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="createRightsForm" id="createRightsForm">
            <div className="row m-0">
              <div className="personalForm">
                <div className="row m-0">
                  {/* Default Industry Form */}
                  <div className="col-md-12">
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        Industry
                        <span>*</span>
                      </label>
                      <div>
                        <Select
                          styles={customStyles2}
                          options={industryData}
                          onChange={handleIndustrySelect} // Handle selection
                          value={selectedIndustries} // Ensure value is bound to selectedIndustries
                          isMulti
                          className={`form-control industry_input inpageinput`}
                          placeholder="Industry"
                          isSearchable
                        />

                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createRightBtnDiv"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="createBtn"
            variant="secondary"
            onClick={handleIndustryCloseModal}
          >
            Close
          </Button>
          <Button
            className="createBtn"
            variant="primary"
            onClick={handleSaveChangesdynamic}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewTemplates;
