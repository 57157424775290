import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  CreateStaffRightText,
  EditStaffPage,
  add_faqs_text,
} from "../CommonJquery/WebsiteText";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_faq,
  get_all_faq,
} from "../ServiceConnection/serviceconnection.js";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

//imports for editor
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Add_Faqs() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [editorData, setEditorData] = useState("");

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let valid_data = check_vaild_save(form_data); // Make sure 'valid' is spelled correctly
    if (valid_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("answer_name_dd", editorData);
  
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
            toast.error(Response.data.message); // Error toast
          }else {
            const toastMessage = editorDataMainID === "0" ? "FAQ added successfully!" : "FAQ updated successfully!";
          toast.success(toastMessage);
            
            setTimeout(() => {
              handleSuccessSession(Response.data.message, "/View_Faq");
            }, 1000);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
          handleError("network");
          toast.error("Network error, please try again.");
        });
    }
  };
  

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    if (call_id != "0") {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("call_id", call_id);
      fd.append("flag", "3");
      await server_post_data(get_all_faq, fd)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            if (Response.data.message.data.length > 0) {
              setEditorDatMainID(Response.data.message.data[0].primary_id);
              seteditStaffData(Response.data.message.data[0]);
              setEditorData(Response.data.message.data[0].answer_name);
              setEditorContent(Response.data.message.data[0].answer_name);
            }
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          console.log(error);
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    const contentState = newState.getCurrentContent();
    const htmlData = draftToHtml(convertToRaw(contentState));
    setEditorData(htmlData);
  };

  const setEditorContent = (htmlContent) => {
    const contentState = stateFromHTML(htmlContent);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  };

  // new editor code ends

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="pageNameDiv">
                <p>
                  <p>
                    {location.pathname.includes("/Edit_Faq")
                      ? EditStaffPage.save_text
                      : CreateStaffRightText.Create_text}{" "}
                    FAQ
                  </p>
                </p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="personalForm">
                      <div className="staffForm_container">
                        <div className="row m-0">
                          <div className={`col-md-12`}>
                            <div className="inpContainer">
                              <label className="no_prsnl_id">
                                {add_faqs_text.Question}
                                <span>*</span>
                              </label>
                              <div>
                                <input
                                  type="text"
                                  id="question"
                                  name="question_name"
                                  className={`  trio_mandatory  input_field_customPadding form-control`}
                                  placeholder={add_faqs_text.Enter_question}
                                  defaultValue={
                                    editStaffData.question_name || ""
                                  }
                                />

                                <span className="condition_error"></span>
                              </div>
                            </div>
                          </div>

                          <div className={`col-md-12`}>
                            <div
                              className="inpContainer mt-2"
                              style={{ width: "98%" }}
                            >
                              <label className="no_prsnl_id">
                                Answer
                                <span>*</span>
                              </label>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid grey",
                                  borderRadius: "5px",
                                  outline: "none",
                                  width: "auto",
                                }}
                              >
                                <Editor
                                  style={{ width: "30%" }}
                                  editorState={editorState}
                                  wrapperClassName="demo-wrapper form-control"
                                  editorClassName="demo-editor"
                                  onEditorStateChange={onEditorStateChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="createRightBtnDiv">
                          <button
                            className="btn btn-primary Create_Right_Btn btnSave"
                            type="button"
                            onClick={() =>
                              handleSaveChangesdynamic(
                                "createRightsForm",
                                save_update_faq
                              )
                            }
                          >
                            {location.pathname.includes("/Edit_Faq")
                              ? EditStaffPage.save_text
                              : CreateStaffRightText.Create_text}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Faqs;
