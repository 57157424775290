import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";

import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_color_combination,
  get_all_faq,
  get_color_combination_data,
} from "../ServiceConnection/serviceconnection.js";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function AddMaster() {
  const location = useLocation();
  // const id = primary_id;
  const { id } = useParams();
  console.log(id);

  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  //master code
  const [isModified, setIsModified] = useState(false);

  const [formValues, setFormValues] = useState({
    color_group: "",
    tagline: "",
    icon: "",
    index: "",
    background: "",
    companyText: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prevValues) => {
      const newValues = { ...prevValues, [name]: value };
      if (name.includes("Picker")) {
        const textInputName = name.replace("Picker", "");
        newValues[textInputName] = value;
      } else {
        const colorPickerName = name + "Picker";
        newValues[colorPickerName] = value;
      }
      return newValues;
    });

    setIsModified(true);
  };

  const colors = [
    { name: "Black", hex: "#888" },
    { name: "Blue", hex: "#00f" },
    { name: "Teal", hex: "#0ff" },
    { name: "Green", hex: "#89d587" },
    { name: "Purple", hex: "#764bc4" },
    { name: "Pink", hex: "#f0f" },
    { name: "Red", hex: "#f06060" },
    { name: "Orange", hex: "#ee8133" },
    { name: "Yellow", hex: "#f8e8a2" },
  ];

  //faq old code

  const [editorDataMainID, setEditorDatMainID] = useState([]);

  const handleSaveChangesdynamic = async (
    form_id,
    url_for_save,
    primary_id
  ) => {
    const valid_data = check_vaild_save(form_id);

    if (valid_data) {
      console.log("aadaad", primary_id);
      setshowLoaderAdmin(true);

      // Combine form data with primary ID
      const fd_from = combiled_form_data(form_id);
      if(!primary_id)
      {
        primary_id=0;
      }
      // Append form data
      fd_from.append("primary_id", primary_id);
      fd_from.append("background", formValues.background);
      fd_from.append("tagline", formValues.tagline);
      fd_from.append("companyText", formValues.companyText);
      fd_from.append("icon", formValues.icon);
      fd_from.append("color_group", formValues.color_group);

      try {
        const response = await server_post_data(url_for_save, fd_from);
        setshowLoaderAdmin(false);

        if (response.data.error) {
          handleError(response.data.message);
        } else {
          // Handle success (e.g., show a success message or redirect)
           handleSuccessSession(response.data.message, "/View_master");
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        handleError("network");
      }
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const url = currentUrl;
    const parts = url.split("/");
    let call_id = 0;
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    if (call_id != 0) {
      console.log(call_id);
      console.log('hiii');
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("primary_id", call_id);
      
      await server_post_data(get_color_combination_data, fd)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            const data = Response.data.data[0];
            console.log(data);
            setFormValues({
              color_group: data.color_group || "",
              tagline: data.tagline,
              icon: data.icon || "", // Handle case where icon might be missing
              index: data.index || "", // Handle case where index might be missing
              background: data.background || "", // Handle case where background might be missing
              companyText: data.companyText || "" // Handle case where companyText might be missing
            });
            setEditorDatMainID(data);
          }
          setshowLoaderAdmin(false);
        })
        .catch((error) => {
          console.log(error);
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };
  console.log(editorDataMainID);

  // new editor code ends

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="pageNameDiv">
                <p>
                  <p>
                    {location.pathname.includes("/edit_master")
                      ? "Update"
                      : "Create"}{" "}
                    Master
                  </p>
                </p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="personalForm">
                      <div className="staffForm_container">
                        <div className="container">
                          <form className="colorCombinationForm">
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label>Color</label>
                              </div>
                              <div className="col-md-4">
                                <select
                                  className="form-control"
                                  name="color_group"
                                  value={
                                    formValues.color_group ||
                                    (editorDataMainID &&
                                      editorDataMainID.color_group)
                                  }
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Color</option>
                                  {colors.map((color) => (
                                    <option key={color.hex} value={color.name}>
                                      {color.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label>Background</label>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="background"
                                  value={
                                    formValues.background ||
                                    (editorDataMainID &&
                                      editorDataMainID.background)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-2">
                                <input
                                  type="color"
                                  className="form-control"
                                  name="background"
                                  value={
                                    formValues.background ||
                                    (editorDataMainID &&
                                      editorDataMainID.background)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label>Company Text</label>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="companyText"
                                  value={
                                    formValues.companyText ||
                                    (editorDataMainID &&
                                      editorDataMainID.companyText)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-2">
                                <input
                                  type="color"
                                  className="form-control"
                                  name="companyText"
                                  value={
                                    formValues.companyText ||
                                    (editorDataMainID &&
                                      editorDataMainID.companyText)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label>Slogan</label>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="tagline"
                                  value={
                                    formValues.tagline ||
                                    (editorDataMainID &&
                                      editorDataMainID.tagline)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-2">
                                <input
                                  type="color"
                                  className="form-control"
                                  name="tagline"
                                  value={
                                    formValues.tagline ||
                                    (editorDataMainID &&
                                      editorDataMainID.tagline)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <label>Icon</label>
                              </div>
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="icon"
                                  value={
                                    formValues.icon ||
                                    (editorDataMainID && editorDataMainID.icon)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="col-md-2">
                                <input
                                  type="color"
                                  className="form-control"
                                  name="icon"
                                  value={
                                    formValues.icon ||
                                    (editorDataMainID && editorDataMainID.icon)
                                  }
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </form>

                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              disabled={!isModified}
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  save_update_color_combination,
                                  id
                                )
                              }
                            >
                              {window.location.pathname.includes("/edit_master")
                                ? "Update"
                                : "Create"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMaster;
