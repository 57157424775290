import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
const appauth_key = "barlays@2029";
let APL_LINK = "http://192.168.1.20:8000/";
APL_LINK = "https://api.logoacademy.co/";
//APL_LINK = "http://127.0.0.1:7998/";
let Website_URL = "https://www.logoacademy.co";
let local_server_link_react = APL_LINK + "websitebackend/";

// API functions
const login_to_superadmin = local_server_link_react + "login_to_superadmin";

const save_update_faq = local_server_link_react + "save_update_faq";
const get_all_faq = local_server_link_react + "get_all_faq";
const get_all_guestInformation =
  local_server_link_react + "get_all_guestInformation";
const update_guest_active_status =
  local_server_link_react + "update_guest_active_status";
const save_update_staff = local_server_link_react + "save_update_staff";
const get_all_staff = local_server_link_react + "get_all_staff";
const action_update_staff = local_server_link_react + "action_update_staff";
const save_update_blog = local_server_link_react + "save_update_blog";
const get_all_blogs = local_server_link_react + "get_all_blogs";
const delete_blog = local_server_link_react + "delete_blog";
const save_update_seo = local_server_link_react + "save_update_seo";
const get_all_seo = local_server_link_react + "get_all_seo";
const delete_admin_template = local_server_link_react + "delete_admin_template";
const delete_admin_imagelink =
  local_server_link_react + "delete_admin_imagelink";
const get_admin_template_data = local_server_link_react + "get_admin_template";
const delete_website_master_data = local_server_link_react + "delete_admin_seo";
const save_update_imagelink = local_server_link_react + "save_update_imagelink";
const get_all_imagelink = local_server_link_react + "get_all_imagelink";
const get_all_package_data = local_server_link_react + "get_all_package_data";
const save_update_Package = local_server_link_react + "save_update_packages";
const delete_Package = local_server_link_react + "delete_package";
const get_all_testi = local_server_link_react + "get_testimonials";
const delete_testimonial_field =
  local_server_link_react + "delete_testimonial_field";
const save_update_Testi = local_server_link_react + "save_update_testimonials";
const get_Industry = local_server_link_react + "get_all_industry_data";
const save_update_industry = local_server_link_react + "save_update_industry";
const delete_industry = local_server_link_react + "delete_industry_field";
const get_Enquiry = local_server_link_react + "get_enquiry_data";
const get_all_designation = local_server_link_react + "get_all_designation";
const save_update_designtion =
  local_server_link_react + "save_update_designtion";
const get_all_searchmaster = local_server_link_react + "get_all_searchmaster";
const save_update_searchmaster =
  local_server_link_react + "save_update_searchmaster";
const save_update_StaffRights =
  local_server_link_react + "save_update_StaffRights";
const get_all_StaffRights = local_server_link_react + "get_all_StaffRights";
const action_update_staff_right =
  local_server_link_react + "action_update_staff_right";
const update_profile_staff = local_server_link_react + "update_profile_staff";
const calculate_revenue_and_sales =
  local_server_link_react + "calculate_revenue_and_sales";
/**not working */
const get_admin_template_view_templates =
  local_server_link_react + "get_admin_template_view_templates";
const get_all_admin_template =
  local_server_link_react + "get_all_admin_template";
const get_all_analyticsReservation =
  local_server_link_react + "get_all_analyticsReservation";
//Old api Links
const get_icon_shape = local_server_link_react + "get_icon_shape";
const get_icon_img = local_server_link_react + "call_noun_project_api";
const get_admin_template_by_id =
  local_server_link_react + "get_admin_template_by_id";

const Remove_upload_img = local_server_link_react + "delete_upload_image";
const delete_upload_pic = local_server_link_react + "delete_upload_pic";
const delete_shape = local_server_link_react + "delete_shape";

const get_staff = local_server_link_react + "get_staff_data";

const get_canvas_industry_icon_data =
  local_server_link_react + "get_canvas_industry_icon_data";
const LastClickedIconsUrl = local_server_link_react + "LastClickedIconsUrl";

const get_template_data = local_server_link_react + "get_template_data";
const get_all_industry_data = local_server_link_react + "get_all_industry_data";
const get_Logo_elements_imgs = local_server_link_react + "get_upload_img_data";
const save_update_templates = local_server_link_react + "save_update_templates";
const save_update_upload_img =
  local_server_link_react + "save_update_upload_img";

const get_admin_template_showLogos =
  local_server_link_react + "get_admin_template_showLogos";

//update
const logo_element_update = local_server_link_react + "save_update_element_img";
const save_update_color_combination =
  local_server_link_react + "save_update_color_combination";

const save_update_admin_templates =
  local_server_link_react + "save_update_admin_templates";

const search_upload_img_data =
  local_server_link_react + "search_upload_img_data";
const get_canvas_editor_data =
  local_server_link_react + "get_canvas_editor_data";
const insert_img_data = local_server_link_react + "save_update_upload_img";
const save_update_upload_picture = local_server_link_react + "save_update_upload_picture";
const post_customfont_data = local_server_link_react + "post_customfont_data";
const savecolorcombination = local_server_link_react + "savecolorcombination";
const get_color_combination_data =
  local_server_link_react + "get_color_combination_data";
const get_color_combinations =
  local_server_link_react + "get_color_combinations";
const toggle_template_fav = local_server_link_react + "toggle_template_fav";
const classify_users = local_server_link_react + "classify_users";
const get_users_with_packages =
  local_server_link_react + "get_users_with_packages";
const save_update_shape = local_server_link_react + "save_update_shape";
const update_template_industry =
  local_server_link_react + "update_template_industry";
const get_more_image_uploads_blog =
  local_server_link_react + "get_more_image_uploads_blog";
const get_more_pic_uploads_blog =
  local_server_link_react + "get_more_pic_uploads_blog";
const get_brandkit_data_adminCanvas =
  local_server_link_react + "get_brandkit_data_adminCanvas";  
  
/**website links */
// Retrieving data

const retrievedAdminId = retrieveData("admin_id");

const server_post_data = async (url_for, form_data) => {
  // const headers = {
  //   "Content-Type": "application/json",
  // };

  if (form_data === null) {
    form_data = new FormData();
  }
  form_data.append("admin_id", retrievedAdminId);
  form_data.append("appauth_key", appauth_key);
  if (form_data.get("data_call") !== null) {
    form_data.append("call_id", retrievedAdminId);
  }
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,
  login_to_superadmin,
  save_update_staff,
  get_all_staff,
  action_update_staff,
  get_all_guestInformation,
  update_profile_staff,
  get_all_StaffRights,
  save_update_StaffRights,
  action_update_staff_right,
  get_all_designation,
  save_update_designtion,
  save_update_faq,
  get_all_analyticsReservation,
  get_all_searchmaster,
  save_update_searchmaster,
  get_admin_template_showLogos,
  delete_testimonial_field,
  get_all_admin_template,
  //website Links
  save_update_blog,
  get_all_blogs,
  save_update_seo,
  get_all_seo,
  save_update_imagelink,
  get_all_imagelink,
  delete_website_master_data,
  get_Enquiry,
  get_Industry,
  get_all_faq,
  save_update_industry,
  delete_industry,
  get_all_testi,
  get_template_data,
  get_Logo_elements_imgs,
  save_update_templates,
  save_update_upload_img,
  delete_blog,
  get_staff,
  save_update_Package,
  get_all_package_data,
  delete_Package,
  Remove_upload_img,
  get_icon_shape,
  get_icon_img,
  logo_element_update,
  save_update_Testi,
  get_admin_template_view_templates,
  LastClickedIconsUrl,
  get_canvas_industry_icon_data,
  get_admin_template_by_id,
  save_update_admin_templates,
  search_upload_img_data,
  update_guest_active_status,
  delete_admin_imagelink,
  get_all_industry_data,
  get_admin_template_data,
  get_canvas_editor_data,
  insert_img_data,
  post_customfont_data,
  delete_admin_template,
  savecolorcombination,
  get_color_combinations,
  save_update_color_combination,
  get_color_combination_data,
  toggle_template_fav,
  classify_users,
  get_users_with_packages,
  save_update_shape,
  update_template_industry,
  calculate_revenue_and_sales,
  delete_shape,
  get_more_image_uploads_blog,
  save_update_upload_picture,
  delete_upload_pic,
  get_more_pic_uploads_blog,
  get_brandkit_data_adminCanvas,
  //website Links
};
