import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "react-bootstrap";
import Header from "./Header";
import Loader from "./Loader.js";
import Delete from "../assets/delete.svg";
import Edit from "../assets/edit_square.png";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  server_post_data,
  get_color_combination_data,
  save_update_faq,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  formatDateStringdot,
  handleConfimDeleteClick,
} from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";

function Master() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [getcolorsData, setgetcolorsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Changed to 10 items per page
  const [selectedColor, setSelectedColor] = useState("");

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get("", "", flag, call_id, "");
  }, []);

  const tableRef = useRef(null);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("call_id", call_id);

    await server_post_data(get_color_combination_data, fd)
      .then((Response) => {
        console.log(Response.data.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgetcolorsData(Response.data.data);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const totalPageCount = Math.ceil(getcolorsData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("main_id", call_id);
      fd.append("flag", "3");
      await server_post_data(save_update_faq, fd)
        .then((Response) => {
          setshowLoaderAdmin(false);
          console.log(Response.data);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const filteredData = selectedColor
    ? getcolorsData.filter((item) => item.color_group === selectedColor)
    : getcolorsData;

  const colorOptions = [
    { label: "All", value: "" },
    { label: "Red", value: "Red" },
    { label: "Blue", value: "Blue" },
    { label: "Green", value: "Green" },
    { label: "Orange", value: "Orange" },
    { label: "Yellow", value: "Yellow" },
    { label: "Teal", value: "Teal" },
    { label: "Purple", value: "Purple" },
    { label: "Pink", value: "Pink" },
    { label: "Black", value: "Black" },
  ];

  // Logic for displaying the correct data based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const reversedData = [...filteredData].reverse();
  const currentData = reversedData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>Color Combinations</p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
              <div className="d-flex gap-2">
                <div className="colortype">
                  <div className="dropdownCustom marginBttm">
                    <select
                      className="dropdownCustom-btn "
                      style={{width:"150px"}}
                      value={selectedColor}
                      onChange={(e) => setSelectedColor(e.target.value)}
                    >
                      {colorOptions.map((items, index) => (
                        <option value={items.value} key={index}>
                          {items.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <Link to="/add_master">
                  <button type="button" className="btnAddStaff darkBg add_FAQ">
                    Add Master
                  </button>
                </Link>
              </div>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container mt-2">
                  <div className="analyticsCardsContainer">
                    <div className="feedBackTable">
                      <div className="feedBackTable_container">
                        <table
                          id="myTable"
                          className="display table"
                          ref={tableRef}
                        >
                          <thead>
                            <tr style={{ color: "white" }}>
                              <th
                                scope="col"
                                className="th3"
                                style={{ color: "white" }}
                              >
                                S. No.
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                Color Type
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                Background
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                Company
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                Slogan
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                {" "}
                                Icon
                              </th>
                              <th className="th4" style={{ color: "white" }}>
                                Action
                              </th>
                            </tr>
                            <tr style={{ height: "25px" }}></tr>
                          </thead>
                          <tbody>
                            {currentData.map((item, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td className="th3">
                                    <div className="recentANme">
                                      <p>{indexOfFirstItem + index + 1}</p>{" "}
                                      {/* Continuous indexing */}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact">
                                      <p>{item.color_group}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact d-flex align-items-center">
                                      <div
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          borderRadius: "50%",
                                          backgroundColor: item.background,
                                          marginRight: "10px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                        }}
                                      ></div>
                                      <p>{item.background}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact d-flex align-items-center">
                                      <div
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          borderRadius: "50%",
                                          backgroundColor: item.companyText,
                                          marginRight: "10px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                        }}
                                      ></div>
                                      <p>{item.companyText}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact d-flex align-items-center">
                                      <div
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          borderRadius: "50%",
                                          backgroundColor: item.tagline,
                                          marginRight: "10px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                        }}
                                      ></div>
                                      <p>{item.tagline}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact d-flex align-items-center">
                                      <div
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          borderRadius: "50%",
                                          backgroundColor: item.icon,
                                          marginRight: "10px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                        }}
                                      ></div>
                                      <p>{item.icon}</p>
                                    </div>
                                  </td>
                                  <td className="th4">
                                    <div
                                      className="recentReservBtns"
                                      style={{ justifyContent: "start" }}
                                    >
                                      <Link
                                        to={`/edit_master/${item.primary_id}`}
                                      >
                                        <button
                                          className="mb-2"
                                          style={{ width: "90px" }}
                                        >
                                          <p>Edit</p>
                                          <img
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                            src={Edit}
                                            alt="Edit"
                                          />
                                        </button>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  style={{ height: "1rem", boxShadow: "none" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <Pagination>
                          <div className="paginationContainer">
                            <div className="nxtBttnTble">
                              {currentPage !== 1 ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setCurrentPage((prev) =>
                                      prev > 1 ? prev - 1 : prev
                                    )
                                  }
                                >
                                  Previous
                                </button>
                              ) : null}
                            </div>
                            <div className="d-flex gap-2">
                              {renderPaginationItems()}
                            </div>
                            <div className="nxtBttnTble">
                              {currentPage !== totalPageCount ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setCurrentPage((prev) =>
                                      prev < totalPageCount ? prev + 1 : prev
                                    )
                                  }
                                >
                                  Next
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Master;
